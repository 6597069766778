(function ($) {
  const pluginName = 'itchyPopover';

  function Plugin (element, options) {
    this.element = element;
    this._name = pluginName;
    this._defaults = $.fn.itchyPopover.defaults;
    this.options = $.extend( {}, this._defaults, options );

    this.init();
  }

  $.extend(Plugin.prototype, {
    // Initialization logic
    init: function () {
      this.buildCache();
      this.bindEvents();
    },

    // Remove plugin instance completely
    destroy: function() {
      this.unbindEvents();
      this.$element.removeData();
    },

    // Cache DOM nodes for performance
    buildCache: function () {
      this.show = false;
      this.$element = $(this.element);
      this.space = 20;

      if (this.$element.data('placement')) {
        this.placement = this.$element.data('placement');
      } else {
        this.placement = 'top';
      }

      if (this.$element.data('target')) {
        this.$target = $(this.$element.data('target'));
      } else if (this.$element.attr('href')) {
        this.$target = $(this.$element.attr('href'));
      } else {
        console.log('Задайте id popover');
      }

      this.$dismiss = this.$target.find('[data-dismiss="popover-itchy"]');
    },

    // Bind events that trigger methods
    bindEvents: function() {
      const plugin = this;

      $('body').on('click' + '.' + plugin._name, function(e) {
        if (!plugin.$element.is(e.target) && plugin.$element.has(e.target).length === 0 && !plugin.$target.is(e.target) && plugin.$target.has(e.target).length === 0 && plugin.show){
          plugin.show = false;
          plugin.toggle.call(plugin);
        }
      });

      plugin.$element.on('click' + '.' + plugin._name, function(e) {
        e.preventDefault();
        plugin.show = !plugin.show;
        plugin.toggle.call(plugin);
      });

      plugin.$dismiss.on('click' + '.' + plugin._name, function() {
        plugin.show = false;
        plugin.toggle.call(plugin);
      });
    },

    // Unbind events that trigger methods
    unbindEvents: function() {
      this.$element.off('.' + this._name);
    },

    toggle: function() {
      if (this.show) {
        this.$target.addClass('show');
        this.$target.addClass(this.placement);

        this.place.call(this);
      } else {
        this.$target.removeClass('show');
        this.$target.removeClass(this.placement);
        this.$target.css('left', '0');
        this.$target.css('top', '-100%');
      }

      this.callback();
    },

    place: function () {
      const offset = this.$element.offset();
      const elementWidth = this.$element.outerWidth();
      const elementHeight = this.$element.outerHeight();
      const targetWidth = this.$target.outerWidth();
      const targetHeight = this.$target.outerHeight();

      switch (this.placement) {
        case 'left': {
          const left = Math.round(offset.left - targetWidth - this.space);
          const top = Math.round(offset.top + elementHeight / 2 - targetHeight / 2);

          this.$target.css('left', left);
          this.$target.css('top', top);
          break;
        }
        case 'right': {
          const left = Math.round(offset.left + elementWidth + this.space);
          const top = Math.round(offset.top + elementHeight / 2 - targetHeight / 2);

          this.$target.css('left', left);
          this.$target.css('top', top);
          break;
        }
        case 'bottom': {
          const left = Math.round(offset.left + elementWidth / 2 - targetWidth / 2);
          const top = Math.round(offset.top + elementHeight + this.space);

          this.$target.css('left', left);
          this.$target.css('top', top);
          break;
        }
        default: {
          const left = Math.round(offset.left + elementWidth /2 - targetWidth / 2);
          const top = Math.round(offset.top - targetHeight - this.space);

          this.$target.css('left', left);
          this.$target.css('top', top);
          break;
        }
      }
    },

    callback: function() {
      // Cache onComplete option
      const onComplete = this.options.onComplete;

      if (typeof onComplete === 'function') {
        onComplete.call(this.element);
      }
    }

  });

  $.fn.itchyPopover = function (options) {
    this.each(function() {
      if ( !$.data( this, 'plugin_' + pluginName ) ) {
        $.data( this, 'plugin_' + pluginName, new Plugin( this, options ) );
      }
    });
    return this;
  };

  $.fn.itchyPopover.defaults = {
    property: 'value',
    onComplete: null
  };

})(jQuery);