var ODPHP = {};

// Common

ODPHP.common = {
  init () {
    $('html').removeClass('no-js').addClass('js');
  },
  finalize () {
    // Скрывает загрузчик
    setTimeout(function () {
      $('.loader').fadeOut();
      $('body').addClass('loaded');
    }, 500);

    // Маска для поля телефон
    $('[data-mask="phone"]').mask('+0 (000) 000 00 00', {
      placeholder: '+7 (___) ___ __ __'
    });

    // Включаем тултипы
    $('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover'
    });

    // Включаем вслпывающие окна
    $('[data-toggle="itchy-popover"]').itchyPopover();

    // Question
    $('[data-toggle="question"]').on('click', function (e) {
      e.preventDefault();
      const $this = $(this);
      const $item = $($this.attr('href'));
      const ariaExpanded = $item.attr('aria-expanded');

      $item.attr('aria-expanded', ariaExpanded === 'true' ? 'false' : 'true');
    });

    // Подробнее
    $('body').on('click', '[data-toggle="collapse"]', function () {
      const $this = $(this);

      if ($this.data('options')) {
        const options = $this.data('options');
        const expanded = $this.attr('aria-expanded');

        if (expanded === 'true') {
          $this.html(options.hidden);
        } else {
          $this.html(options.visible);
        }
      }
    });

    const hash = window.location.hash;

    if (hash) {
      // Новости
      const $hash = $(hash);

      if ($hash.length && $hash.hasClass('news-item') && $hash.find('[data-toggle="collapse"]').length) {
        $hash.find('[data-toggle="collapse"]').trigger('click');
      }

      // Тарифы
      const $toggleHash = $('[data-collapse="' + hash +'"]');

      if ($toggleHash.length && $toggleHash.attr('aria-expanded') === 'false') {
        $toggleHash.trigger('click');
      }
    }
  }
};

const UTIL = {
  fire (func, funcname, args) {
    const namespace = ODPHP;
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
      namespace[func][funcname](args);
    }
  },
  loadEvents () {
    UTIL.fire('common');
    $.each(document.body.className.replace(/-/g, '_').split(/\s+/), (i,classnm) => {
      UTIL.fire(classnm);
      UTIL.fire(classnm, 'finalize');
    });
    UTIL.fire('common', 'finalize');
  }
};

// Load Events
jQuery(document).ready(() => UTIL.loadEvents());
